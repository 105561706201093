import Suggestedpromptbox from '../../../components/Suggestedpromptbox/Suggestedpromptbox';
import React from 'react';

export default function ChatForm({ handleRedirectToMentor }) {
  return (
    <div className="bottom-area">
      <div className="form-block w-form">
        <form
          className="form"
          data-name="user-prompt"
          data-wf-element-id="2cfcedfe-aeba-88e5-d900-4a8156964a67"
          data-wf-page-id="650305782bc61751e5765214"
          id="wf-form-user-prompt"
          method="post"
          name="wf-form-user-prompt"
          action="#"
          prop-events-names="onSubmit"
          onSubmit={handleRedirectToMentor}
          prop-events-value-onsubmit="handleUserPromptFormSubmit"
        >
          <div
            className="w-layout-hflex new-chat-block whiten-border-in-dark-mode all-whiten-in-dark-mode"
            data-w-id="9e41a1e7-5b47-4410-733f-70324ffe5f9d"
          >
            <img
              alt=""
              className="image-34"
              loading="lazy"
              src="/images/plus-4.svg"
            />
          </div>
          <div className="input-container">
            <div
              className=""
              style={{ position: 'relative', marginTop: '10px' }}
            >
              <textarea
                className="user-prompt-input-field black-bg-dark-mode whiten-border-in-dark-mode whiten-in-dark-mode w-input"
                data-name="Name"
                id="user-prompt"
                name="prompt"
                autoFocus
                rows="1"
                readOnly={true}
                onClick={handleRedirectToMentor}
                placeholder="Type your prompt"
                style={{ paddingRight: '10px' }}
              />
            </div>
            <div className="w-layout-hflex flex-block-24 suggested-prompt-box-container-custom">
              <div className="w-layout-hflex flex-block-25">
                <div className="w-layout-hflex mentor-footer-action-block">
                  <Suggestedpromptbox
                    isShare={true}
                    handleRedirectToMentor={handleRedirectToMentor}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="input-submit-container"
            style={{ marginBottom: '54px' }}
            prop-events-names="onClick"
            onClick={handleRedirectToMentor}
            prop-events-value-onclick="handleUserPromptFormSubmit"
          >
            <img
              alt=""
              className={`input-submit-icon whiten-in-dark-mode input-submit-icon-enabled`}
              loading="lazy"
              src="/images/send-svgrepo-com.svg"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
