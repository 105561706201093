/* eslint-disable */

import React from 'react';
import { transformProxies } from './helpers';
import Home from '../modules/home';

const scripts = [
  {
    loading: Promise.resolve(
      'Webflow.push(function(){$("input").length&&$("input").each(t=>{var u=$(this).attr("custom-value");u&&$(this).val(u)})});'
    ),
    isAsync: false,
  },
  {
    loading: Promise.resolve(
      'jQuery(function(t){t(".switch > input").on("change",function(){t(this).is(":checked")?t("body .instructor-label").attr("style","color:rgb(36, 103, 235)!important;"):t("body .instructor-label").attr("style","color:inherit")})});'
    ),
    isAsync: false,
  },
  {
    loading: Promise.resolve(
      'jQuery(function(t){t(".history-menu-rename-btn").on("click",function(){var e=t(this).closest(".history-element").find(".history-element-label").text();t(".modal.rename-history-modal form input.rename-chat-form-input").val(removeSpaceFromText(e))}),t(".history-menu-pin-btn").on("click",function(){var e=t(this).closest(".history-element").find(".history-element-label").text();t(".modal.pin-history-modal form input.rename-chat-form-input").val(removeSpaceFromText(e))})});'
    ),
    isAsync: false,
  },
  {
    loading: Promise.resolve(
      'jQuery(function(e){e(".modal.add-new-prompt-modal .modal-header form input.add-new-prompt-input-title").val("Untitled Prompt")}),jQuery(function(n){n(".modal.edit-mentor-modal .menu-container .menu-element-block:not(.no-border-bottom-on-click)").on("click",function(e){e.preventDefault(),n(".modal.edit-mentor-modal .menu-container .menu-element-block").removeClass("current"),n(this).addClass("current")})}),jQuery(function(n){n(".modal.mentor-creation-modal .menu-container .menu-element-block:not(.no-border-bottom-on-click)").on("click",function(e){e.preventDefault(),n(".modal.mentor-creation-modal .menu-container .menu-element-block").removeClass("current"),n(this).addClass("current")})}),jQuery(function(e){e(".top-header-closer-container").on("click",function(){e(this).closest(".site-top-header").addClass("hidden")})}),jQuery(function(e){e(".interface-mentor-name-input").val("mentorAI"),e(".mentor-default-prompt1-textarea").val("Generate a daily study schedule for the next week, including specific times for each subject and any breaks or activities planned"),e(".interface-welcome-textarea").val("I’m mentorAI, your AI helpful assistant. Not sure where to start? You can try:")});'
    ),
    isAsync: false,
  },
];

let Controller;

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require('../controllers/IndexController');
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = '650305782bc61751e5765214';
    htmlEl.dataset['wfSite'] = '650305782bc61751e5765211';

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function().call(window, script);
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      IndexView.Controller !== IndexView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <span className="af-view">
          <Home />
        </span>
      </span>
    );
  }
}

export default IndexView;

/* eslint-enable */
