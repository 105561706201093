import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useFreeTrial from '../freetrial/useFreeTrial';
import { toast } from 'react-hot-toast';
import { Error } from '../../modules/alerts';
import { useEffect, useState } from 'react';
import useGuidedSuggestedPrompts from './useGuidedSuggestedPrompts';
let chatSocket = null;
let responseInProgress = '';

export default function useWelcome() {
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const pathParams = useParams();
  const [stateResponseInProgress, setStateResponseInProgress] = useState(true);
  const { loadGuidedPrompts } = useGuidedSuggestedPrompts();

  const { reloadFreeTrialCount } = useFreeTrial(true);

  const chatPayload = {
    flow: {
      name: mentors?.mentor?.unique_id,
      tenant: pathParams?.platform ? pathParams.platform : auth?.tenant?.key,
      username: auth?.user?.user_nicename,
      pathway: mentors?.mentor?.name,
    },
    session_id: sessionId,
    token: localStorage.getItem('axd_token'),
  };

  const _initiateConnection = () => {
    try {
      chatSocket?.send(JSON.stringify(chatPayload));
    } catch (e) {
      console.log(e);
    }
  };

  const _handleSendTextQuery = () => {
    const payload = {
      ...chatPayload,
      prompt: mentors?.mentor.proactive_prompt,
    };
    chatSocket?.send(JSON.stringify(payload));
  };

  const _handleIncomingMessage = (event) => {
    let response = JSON.parse(event.data);

    if (response?.detail === 'Connected.') {
      _handleSendTextQuery();
    }

    if (response?.data) {
      responseInProgress += response?.data;
      setStateResponseInProgress(responseInProgress);
    }

    if (response?.eos) {
      responseInProgress = '';
      _endConnection();
      loadGuidedPrompts();
    }

    if (response?.error) {
      toast.custom(Error(response.error));
      _endConnection();
    }
  };

  const _endConnection = () => {
    if (chatSocket?.readyState === WebSocket.OPEN) {
      chatSocket?.close();
    }
  };

  const handleSendProactivePrompt = () => {
    chatSocket = new WebSocket(
      `${process.env.REACT_APP_BASE_WS_URL}/ws/langflow/`
    );

    chatSocket.onmessage = _handleIncomingMessage;
    chatSocket.onopen = () => {
      setTimeout(_initiateConnection, 1000);
    };
  };

  useEffect(() => {
    if (sessionId) {
      const proActivePrompt = mentors?.mentor?.proactive_prompt;
      if (proActivePrompt) {
        handleSendProactivePrompt();
      } else {
        loadGuidedPrompts();
      }
    }
  }, [sessionId]);

  return { response: stateResponseInProgress, handleSendProactivePrompt };
}
