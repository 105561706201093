import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mode: 'default',
  advanceActiveTab: '',
  tabs: [],
  hostInfo: null,
};
const embedSlice = createSlice({
  name: 'embed',
  initialState,
  reducers: {
    tabsUpdated: (state, action) => {
      state.tabs = action.payload;
    },
    modeUpdated: (state, action) => {
      state.mode = action.payload;
    },
    advanceActiveTabUpdated: (state, action) => {
      state.advanceActiveTab = action.payload;
    },
    hostInfoUpdated: (state, action) => {
      state.hostInfo = action.payload;
    }
  },
});

export const embedActions = embedSlice.actions;
export default embedSlice.reducer;
