import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import useApi from '../base/useApi';
import { toast } from 'react-hot-toast';
import { promptActions } from '../../../lib/redux/prompts/slice';
import usePrompts from './usePrompts';
import { useTriggers } from '../../navigation';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import { Error } from '../../../modules/alerts';
import useMentorUtils from '../../utils/useMentorUtils';

export default function useEditPrompt(prompt) {
  const [openEdit, setOpenEdit] = useState(true);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(prompt);
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const loadedPrompts = useSelector(
    (state) => state.prompts.data.loadedPrompts
  );

  const { _handleUpdateModalStatus } = useTriggers();
  const { updateCurrentEditMentor } = useMentorUtils();

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const dispatch = useDispatch();
  const { handlePromptDataSuccess } = usePrompts();

  const api = useApi();

  const makeRequest = async (url, payload) => {
    setSaving(true);
    const response = await api.put(url, payload);
    if (response?.data) {
      toast.success('Prompt has been updated');
    } else {
      toast.custom(Error(response?.error));
    }
    setSaving(false);
    setOpenEdit(false);
    _handleUpdateModalStatus('prompt-gallery-element-view-modal', false);
    updateCurrentEditMentor({
      ...mentor,
      system_prompt: payload.system_prompt ?? mentor?.system_prompt,
      moderation_system_prompt:
        payload.moderation_system_prompt ?? mentor?.moderation_system_prompt,
      proactive_prompt: payload.proactive_prompt ?? mentor?.proactive_prompt,
      safety_system_prompt:
        payload?.safety_system_prompt ?? mentor?.safety_system_prompt,
      safety_response: payload?.safety_response ?? mentor?.safety_response,
      moderation_response:
        payload?.moderation_response ?? mentor?.moderation_response,
      lastUpdated: new Date().toISOString(),
    });
  };

  const handleUpdateSuggestedPrompt = async () => {
    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompt/${prompt.index}/`;
    const payload = {
      prompt: DOMPurify.sanitize(data.description),
      is_system: false,
      //index: prompt.index,
    };
    await makeRequest(url, payload);

    if (mentors?.mentor?.unique_id === mentor?.unique_id) {
      const newSuggestedPrompts = mentor?.suggested_prompts?.map(
        (item, index) => {
          if (index === prompt.index) {
            return DOMPurify.sanitize(data.description);
          }
          return item;
        }
      );

      dispatch(
        mentorActions.currentMentorUpdated({
          ...mentor,
          suggested_prompts: newSuggestedPrompts,
        })
      );
    }
  };

  const handleUpdateSystemPrompt = async (target = 'system_prompt') => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${mentor?.unique_id}/settings/`;
    const payload = {
      [target]: DOMPurify.sanitize(data.description),
    };
    await makeRequest(url, payload);
  };

  const updateLocalPromptItem = (updatedPrompt) => {
    const newLoadedPrompts = loadedPrompts?.map((item) => {
      if (item.id === updatedPrompt.id) {
        return updatedPrompt;
      }
      return item;
    });
    handlePromptDataSuccess(newLoadedPrompts);
  };

  const handleSave = async (event) => {
    event?.preventDefault();
    if (mentor) {
      if (prompt.isSystem) {
        await handleUpdateSystemPrompt(prompt?.type ?? 'system_prompt');
        return;
      }
      await handleUpdateSuggestedPrompt();
      return;
    }

    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompt/${prompt.id}/`;
    const payload = {
      prompt: data.prompt,
      description: DOMPurify.sanitize(data.description),
    };
    await makeRequest(url, payload);
    const updatedPrompt = {
      ...prompt,
      ...payload,
    };
    dispatch(promptActions.selectedPromptUpdated(updatedPrompt));
    updateLocalPromptItem(updatedPrompt);
  };

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  return {
    handleOpenEdit,
    openEdit,
    handleChange,
    handleSave,
    saving,
    data,
    setOpenEdit,
  };
}
