import Logo from '../header/Logo';
import CollapsedSidebarNewChat from './new-chat';
import Sidebar from '../../components/Sidebar/Sidebar';
import SidebarBottomBlock from './sidebar-bottom-block';
import React from 'react';
import { useSelector } from 'react-redux';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import useEmbedMode from '../../hooks/iframe/useEmbedMode';
import { useMenus, useTriggers } from '../../hooks/navigation';
import { useHeader } from '../../hooks/header/useHeader';

export default function FullSidebar() {
  const { modals, sidebar, mobileSidebar, menus } = useSelector(
    (state) => state.navigation.data
  );
  const { tabs, mode } = useSelector((state) => state.embed);
  const anonymous = useAnonymousMode();
  const embed = useEmbedMode();
  const { handleHeaderMenuToggleClick, handleHelpMenuBtnClick } = useTriggers();
  const { refs } = useMenus();
  const pathName = window.location.pathname;
  const onShare = pathName?.includes('/share/chat/');

  const { HELP_THREE_DOT_MENU_ENABLED } = useHeader();

  return (
    <>
      {!embed && (
        <div
          className={`w-layout-hflex site-left-sidebar-block black-bg-dark-mode`}
          style={{ width: sidebar ? '292px' : '45px' }}
        >
          <div
            className="w-layout-hflex flex-block-92"
            style={{ flex: mode !== 'advanced' ? 'unset' : '' }}
          >
            <div
              behave-as-btn="1"
              prop-events-names="onClick"
              prop-events-value-onclick="handleHeaderMenuToggleClick"
              tabIndex="99"
              data-w-id="8f2ed2b8-d3dd-1bce-014c-90f64c8654d5"
              className="menu-toggler-v2 greyish-bg-in-darkmode"
              onClick={handleHeaderMenuToggleClick}
            >
              <img
                src="/images/align-justify.svg"
                loading="lazy"
                alt="View and Hide Recent Messages Tab"
                width="24"
                className="menu-toggler-icon whiten-in-dark-mode"
              />
            </div>
            <div
              className="logo-container"
              style={{ display: sidebar ? 'flex' : 'none' }}
            >
              <Logo />
            </div>
          </div>
          {!sidebar && <CollapsedSidebarNewChat />}
          {!anonymous && <Sidebar />}
          <SidebarBottomBlock />
        </div>
      )}
    </>
  );
}
