import { memo } from 'react';

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './RichTextEditor.css';

export const RichTextEditor = memo(function RichTextEditor({
  value,
  onChange,
  name,
  ...rest
}) {
  const handleChange = (newValue) => {
    if (onChange) {
      onChange({ target: { name, value: newValue } });
    }
  };

  return <ReactQuill value={value} onChange={handleChange} {...rest} />;
});
