import { useSelector } from "react-redux";
import './translate-query.css';

export const TranslateQuery = ({onLanguageSelect}) => {
    const mentors = useSelector((state) => state.mentors);
    return (
        <div className="w-layout-vflex message-bubble-2">
            <div className="w-layout-hflex flex-block-69">
            <div className="ai-gif-container">
                <img
                    alt=""
                    className="image-3"
                    loading="lazy"
                    style={{ borderRadius: '50%' }}
                    src={
                    mentors?.mentor?.settings?.profile_image ??
                    '/images/ai-academy-p-500.png'
                    }
                />
            </div>
                <div className="w-layout-vflex question-and-answer-column-3">
                    <div prop-data-names="label" className="bodytextbold">Would you like me to translate?</div>
                    <div className="text-block-62"><strong className="bold-text-11">Suggested Languages:</strong></div>
                    <a onClick={() => onLanguageSelect('English')} role="button" className="button-13 w-button">English</a>
                    <a onClick={() => onLanguageSelect('French')} className="button-13 w-button">French (France)</a>
                    <a onClick={() => onLanguageSelect('Spanish')} className="button-13 w-button">Spanish (Espanol)</a>
                </div
            ></div>
        </div>
    );
}