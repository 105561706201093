import React from 'react';
import './Userssettingsmodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import UserItem from '../../modules/users/user-item';
import useUsers from '../../hooks/users/useUsers';
import Loader from '../../modules/loader';
import { useTranslation } from 'react-i18next';

const Userssettingsmodalbox = (props) => {
  const { handleModalCloseClick, handleInviteUserBtnClick } = useTriggers();
  const { t } = useTranslation();
  const { users, loading, handleChange } = useUsers();
  return (
    <div className="modal users-settings-modal">
      <div className="w-layout-vflex modal-container users-settings-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Users</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body user-settings-modal-body">
          <div className="text-block-25">
            {t('Showing list of users interacting with the mentorAI')}
          </div>
          <div className="w-layout-hflex table-top-header-block">
            <div className="w-layout-hflex search-box-block all-whiten-in-dark-mode">
              <img
                alt=""
                className="image-49"
                loading="lazy"
                src="/images/search-1.svg"
              />
              <div className="search-box-form-block w-form">
                <form
                  data-name="Email Form 4"
                  data-wf-element-id="2cb957f0-5e78-5218-d449-943ec6fd3b8e"
                  data-wf-page-id="650305782bc61751e5765214"
                  id="email-form-4"
                  method="get"
                  name="email-form-4"
                >
                  <input
                    className="text-field-4 w-input"
                    data-name="Name 5"
                    id="name-5"
                    maxLength={256}
                    name="name-5"
                    onChange={handleChange}
                    placeholder="Search users"
                    type="text"
                  />
                </form>
              </div>
            </div>
            <div
              className="button-with-icon users-settings-invite-btn"
              data-w-id="779dd34a-a2b2-3723-4859-e98e8c393e0b"
              onClick={handleInviteUserBtnClick}
            >
              <img
                alt=""
                className="button-icon"
                loading="lazy"
                src="/images/user-black-thick.svg"
                width={70}
              />
              <div className="button-text">{t('Invite')}</div>
            </div>
          </div>

          <table id="users-table">
            <thead className="">
              <tr>
                <th>
                  <h5 className="table-title">{t('ADDED ON')}</h5>
                </th>
                <th>
                  <h5 className="table-title">{t('USERNAME')}</h5>
                </th>
                <th>
                  <h5 className="table-title">{t('EMAIL')}</h5>
                </th>

                <th>
                  <h5 className="table-title">{t('PERMISSION')}</h5>
                </th>

                <th>
                  <h5 className="table-title">{t('ACTIVE')}</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={5}>
                    <Loader />
                  </td>
                </tr>
              )}

              {users?.results?.map((user) => (
                <UserItem key={user.user_id} user={user} />
              ))}

              {users && (users?.next_page || users?.previous_page) && (
                <tr className="table-body-row">
                  <td colSpan="4">
                    <div className="w-layout-hflex users-settings-pagination-block">
                      <div className="w-layout-hflex users-settings-pagination-container all-whiten-in-dark-mode">
                        <div
                          className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode"
                          style={{ display: 'none' }}
                        >
                          <img
                            src="/images/chevron-first.svg"
                            loading="lazy"
                            alt=""
                            className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                          />
                        </div>
                        {users?.previous_page && (
                          <div
                            onClick={() => {}}
                            className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode"
                          >
                            <img
                              src="/images/chevron-left-2.svg"
                              loading="lazy"
                              alt=""
                              className="table-pagination-btn-icon"
                            />
                          </div>
                        )}
                        <div className="w-layout-hflex table-pagination-btn table-pagination-btn-active">
                          <h5 className="table-pagination-page-number primary-color-in-dark-mode">
                            {users?.next_page - 1}
                          </h5>
                        </div>

                        {users?.next_page && (
                          <div
                            onClick={() => {}}
                            className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode"
                          >
                            <img
                              src="/images/chevron-right.svg"
                              loading="lazy"
                              alt=""
                              className="table-pagination-btn-icon"
                            />
                          </div>
                        )}
                        <div
                          className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode"
                          style={{ display: 'none' }}
                        >
                          <img
                            src="/images/chevron-last-1.svg"
                            loading="lazy"
                            alt=""
                            className="table-pagination-btn-icon"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Userssettingsmodalbox;
