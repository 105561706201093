import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { chatActions } from '../../../lib/redux/chat/slice';
import useApi from '../base/useApi';
import useAnonymousMode from '../../iframe/useAnonymousMode';

export default function usePinnedMessages() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth.data);
  const pinMessages = useSelector((state) => state.chat.pinMessages);
  const anonymous = useAnonymousMode();

  const api = useApi();

  const loadPinnedMessages = async () => {
    if (anonymous || !auth?.user?.user_nicename || !auth?.tenant?.key) {
      return;
    }

    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/pin-message/`;
    setLoading(true);
    const response = await api.get(endpoint);
    if (response?.data) {
      dispatch(chatActions.pinMessagesUpdated(response.data));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!pinMessages && !loading) {
      loadPinnedMessages();
    }
  }, [pinMessages]);

  return { loading };
}
