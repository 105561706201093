export const endpoints = {
  getConversationVolume: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/conversation/`,

  getCustomTopics: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/topics/`,

  getUserSentimentCount: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/sentiment-count/`,

  getUserRatings: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/user-feedback/`,

  getAnalyticsOverviewSummary: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/overview-summary/`,

  getTopicsAnalytics: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/most-discussed-topics/`,

  getUserSentiment: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/user-sentiment/`,

  getAverageMessagesPerSession: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/average-messages-per-session/`,

  getUserMetricsSummary: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/user-metrics/`,

  getUserCohortsOverTime: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/user-cohorts-over-time/`,

  getUserMetricsPie: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/user-metrics-pie-chart/`,

  getRegisteredUsers: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/registered-users-trend/`,

  getUniqueUsers: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/total-users-by-mentor/`,

  getMostEngagedUsers: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/top-students-by-chat-messages/`,

  getTopTopics: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/topics/summary/`,

  getTopicsSummary: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/topic-overview/`,

  getSuggestedTopics: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/topic-statistics/`,

  getTranscriptFilters: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/chat-history-filter/`,

  getTranscripts: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/chat-history/`,

  getTranscriptsSummary: (args) =>
    `/api/ai-analytics/orgs/${args.org}/users/${args.userId}/conversation-summary/`,
};
