import './StaticPromptButton.css';
const StaticPromptButton = ({ icon, prompt, onSelectPrompt }) => (
  <div
    className="w-layout-blockcontainer messagebtn w-container"
    onClick={() => onSelectPrompt && onSelectPrompt(prompt)}
  >
    <div className="w-layout-hflex static-message whiten-in-dark-mode">
      <div className="text-block-115">{icon}</div>
      <div prop-data-names="label" className="prompts-text">
        {prompt}
      </div>
    </div>
  </div>
);

export default StaticPromptButton;
