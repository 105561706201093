import React from 'react';
import usePromptItem from '../../hooks/api/prompts/usePromptItem';
import { userIsOnTrial } from '../../utils/shared';
import { useTriggers } from '../../hooks/navigation';
import { richTextEditorDivClassWrapper } from '../../utils/rich-text-editor';

export default function PromptItem({ prompt }) {
  const {
    handleCopyPrompt,
    handleTriggerPrompt,
    handleShowPromptDetail,
    handleDeleteSuggestedPrompt,
  } = usePromptItem(prompt);

  const { handleOpenFreeTrialModal } = useTriggers();

  const trimmedPrompt =
    prompt.prompt.length > 27
      ? prompt.prompt.substring(0, 35) + '...'
      : prompt.prompt;

  return (
    <div
      id="w-node-_78e44e4f-58e0-5de2-4d4b-e90ad7c59527-e5765214"
      data-w-id="78e44e4f-58e0-5de2-4d4b-e90ad7c59527"
      className="w-layout-vflex prompt-grid-element light-grey-bg-dark-mode greyish-bg-in-darkmode"
    >
      <div
        data-w-id="48ea1512-1fcb-7dbe-3f17-9cb4d94b5032"
        className="w-layout-vflex prompt-grid-header"
      >
        <div
          onClick={
            userIsOnTrial() ? handleOpenFreeTrialModal : handleShowPromptDetail
          }
          className="w-layout-vflex prompt-grid-header-block"
        >
          <img
            src="/images/store.svg"
            loading="lazy"
            alt=""
            className="prompt-grid-icon"
          />
          <h5
            className="prompt-grid-title"
            dangerouslySetInnerHTML={{
              __html: richTextEditorDivClassWrapper(
                trimmedPrompt,
                'ql-six-hundred-weight'
              ),
            }}
          />
          <div className="prompt-grid-header-form-block w-form">
            <form
              id="email-form-7"
              name="email-form-7"
              data-name="Email Form 7"
              method="get"
              className="prompt-grid-header-form"
              data-wf-page-id="650305782bc61751e5765214"
              data-wf-element-id="e053ec11-7beb-f705-4dab-625124c0e05b"
            >
              <input
                type="text"
                className="prompt-grid-header-form-input-field w-input"
                maxLength="256"
                name="name-4"
                data-name="Name 4"
                placeholder="Prompt Title"
                id="name-4"
              />
            </form>
          </div>
        </div>
        <div
          className="w-layout-hflex prompt-grid-description-block"
          onClick={
            userIsOnTrial() ? handleOpenFreeTrialModal : handleShowPromptDetail
          }
        >
          <div
            className="prompt-grid-description"
            dangerouslySetInnerHTML={{
              __html: richTextEditorDivClassWrapper(
                prompt.description,
                'ql-opacity-seven'
              ),
            }}
          />
          <div className="prompt-grid-description-form-block w-form">
            <form
              id="email-form-8"
              name="email-form-8"
              data-name="Email Form 8"
              method="get"
              className="prompt-grid-description-form"
              data-wf-page-id="650305782bc61751e5765214"
              data-wf-element-id="8b88b28e-fae9-5a52-95b1-e424d2000e9a"
            >
              <textarea
                placeholder="Prompt"
                maxLength="5000"
                id="field-6"
                name="field-6"
                data-name="Field 6"
                className="prompt-grid-description-form-textarea w-input"
              ></textarea>
            </form>
          </div>
        </div>
      </div>

      <div className="w-layout-hflex prompt-grid-footer-block">
        {
          <div
            onClick={
              userIsOnTrial()
                ? handleOpenFreeTrialModal
                : handleShowPromptDetail
            }
            data-w-id="3863898e-8e6a-f978-ce6e-3ec678877c30"
            className="w-layout-hflex gallery-element-btn gallery-element-edit-big-btn"
          >
            <img
              src="/images/pencil.svg"
              loading="lazy"
              alt=""
              className="gallery-element-btn-icon"
            />
            <div>Edit</div>
          </div>
        }
        {!prompt?.creationMode && !prompt.isSystem && (
          <div
            onClick={handleTriggerPrompt}
            className="w-layout-hflex gallery-element-btn"
          >
            <img
              src="/images/terminal.svg"
              loading="lazy"
              alt=""
              className="gallery-element-btn-icon"
            />
            <div>Run</div>
          </div>
        )}
        <div
          onClick={handleCopyPrompt}
          className="w-layout-hflex gallery-element-btn"
        >
          <img
            src="/images/copy.svg"
            loading="lazy"
            alt=""
            className="gallery-element-btn-icon"
          />
          <div>Copy</div>
        </div>

        {!prompt?.isSystem && (
          <div
            onClick={
              userIsOnTrial()
                ? handleOpenFreeTrialModal
                : handleDeleteSuggestedPrompt
            }
            className="w-layout-hflex gallery-element-btn"
          >
            <img
              src="/images/trash-2.svg"
              loading="lazy"
              alt=""
              className="gallery-element-btn-icon"
            />
            <div>Delete</div>
          </div>
        )}

        <div className="w-layout-hflex gallery-element-btn hidden">
          <img
            src="/images/pin.svg"
            loading="lazy"
            alt=""
            className="gallery-element-btn-icon"
          />
          <div>Pin</div>
        </div>
      </div>
      <div className="w-layout-vflex mentor-element-action-block gallery-element-action-block all-whiten-in-dark-mode">
        <a
          href="#"
          className="mentor-element-edit-action gallery-element-edit-btn w-inline-block"
        >
          <div
            data-w-id="3f5e2846-a1c0-8b99-a204-62103501db38"
            className="w-layout-hflex mentor-element-action light-grey-bg-dark-mode"
          >
            <div className="tooltip-3 tooltip-edit-action">Edit</div>
            <img
              src="/images/pencil.svg"
              loading="lazy"
              alt=""
              className="mentor-element-action-icon"
            />
          </div>
        </a>
        <div
          data-w-id="3f5e2846-a1c0-8b99-a204-62103501db3c"
          className="w-layout-hflex mentor-element-action gallery-element-save-btn light-grey-bg-dark-mode"
        >
          <div className="tooltip-3 tooltip-remove-action tooltip">Save</div>
          <img
            src="/images/save-3.svg"
            loading="lazy"
            alt=""
            className="mentor-element-action-icon gallery-save-icon"
          />
        </div>
        <a
          href="#"
          className="mentor-element-edit-action gallery-element-pin-btn w-inline-block"
        >
          <div
            data-w-id="5a9c73dd-645e-cfa3-b3df-30bf5148b2ba"
            className="w-layout-hflex mentor-element-action light-grey-bg-dark-mode"
          >
            <div className="tooltip-3 tooltip-edit-action tooltip">Pin</div>
            <img
              src="/images/pin.svg"
              loading="lazy"
              alt=""
              className="mentor-element-action-icon"
            />
          </div>
        </a>
      </div>
    </div>
  );
}
