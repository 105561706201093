import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import { navigationActions } from '../../lib/redux/navigation/slice';
import { useNavigate, useParams } from 'react-router-dom';

export default function useNewChat() {
  const chat = useSelector((state) => state.chat);
  const nav = useSelector((state) => state.navigation.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const handleNewChatBtnClick = () => {
    window.creatingSession = false;
    dispatch(chatActions.chatSequenceUpdated(chat.sequence + 1));
    dispatch(
      navigationActions.navigationUpdated({ ...nav, mobileSidebar: false })
    );

    const currentPath = window.location.pathname;
    if (currentPath.includes('/analytics')) {
      navigate(`/platform/${params.platform}/${params.mentor}`);
    }
  };

  return { handleNewChatBtnClick };
}
