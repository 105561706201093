import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import useSuggestedPrompt from './useSuggestedPrompt';
import useApi from '../api/base/useApi';
import { useEffect } from 'react';
import usePreviewMode from '../iframe/usePreviewMode';
import { getTabsAndMessages } from '../../modules/platform/data';

export default function useSetChatSession() {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const chat = useSelector((state) => state.chat);
  const messages = useSelector((state) => state.chat.messages);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const { mode, advanceActiveTab } = useSelector((state) => state.embed);
  const embedPreview = usePreviewMode();
  const api = useApi();
  const dispatch = useDispatch();

  const createChatSession = async (resetMessages = true) => {
    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/sessions/?seq=${chat?.sequence}`;
    const payload = {
      mentor: mentors?.mentor?.name,
    };

    const response = await api.post(endpoint, payload);
    if (response?.data) {
      if (mode === 'advanced' && advanceActiveTab) {
        dispatch(
          chatActions.tabbedSessionsUpdated({
            ...chat.tabbedSessions,
            [advanceActiveTab]: response?.data.session_id,
          })
        );
      } else {
        dispatch(chatActions.sessionUpdated(response?.data.session_id));
      }
      if (resetMessages) {
        if (mode === 'advanced') {
          // dispatch(
          //   chatActions.tabbedMessagesUpdated({
          //     ...chat.tabbedMessages,
          //     [embed.advanceActiveTab]: getTabsAndMessages(),
          //   })
          // );
        } else {
          dispatch(chatActions.messagesUpdated([]));
        }
      }
    }
  };

  return { createChatSession };
}
