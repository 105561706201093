/* eslint-disable */

import React from 'react';
import { createScope, map, transformProxies } from './helpers';

const scripts = [
  {
    loading: Promise.resolve(
      'Webflow.push(function(){$("input").length&&$("input").each(t=>{var u=$(this).attr("custom-value");u&&$(this).val(u)})});'
    ),
    isAsync: false,
  },
];

let Controller;

class WelcomeView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require('../controllers/WelcomeController');
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = WelcomeView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = '650305782bc61751e5765221';
    htmlEl.dataset['wfSite'] = '650305782bc61751e5765211';

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function().call(window, script);
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      WelcomeView.Controller !== WelcomeView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/webflow.css);
          @import url(/css/ibl-aimentorpro.webflow.css);


          @supports (-webkit-overflow-scrolling: touch) {
             input:focus, textarea:focus {
               font-size: 16px;
             }
          }
        `,
          }}
        />
        <span className="af-view">
          <div className="body-2">
            <div className="w-layout-vflex welcome-page-container">
              <div className="w-layout-vflex welcome-page-block">
                <div className="w-layout-vflex flex-block-23">
                  <img
                    alt=""
                    className="image-29"
                    loading="lazy"
                    src="/images/ibl-logo-animated.gif"
                  />
                  <div className="text-block-27">Welcole to mentorAI</div>
                </div>
                <div className="text-block-28">
                  Log in with your mentorAI account to continue
                  <br />
                </div>
                <div className="w-layout-hflex welcome-btn-container">
                  <a className="default-btn w-button" href="login.html">
                    Log In
                  </a>
                  <a
                    className="default-btn upgrade-account-btn w-button"
                    href="signup.html"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
              <div className="w-layout-hflex auth-footer">
                <a className="link-block w-inline-block" href="#">
                  <div className="auth-footer-links">Terms of Use</div>
                </a>
                <div className="div-block-35"></div>
                <a className="link-block w-inline-block" href="#">
                  <div className="auth-footer-links">Privacy Policy</div>
                </a>
              </div>
            </div>
          </div>
        </span>
      </span>
    );
  }
}

export default WelcomeView;

/* eslint-enable */
