import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import React from 'react';

export default function DocumentPreviewModal() {
  const file = useSelector((state) => state.chat.documentMessage);
  const show = useSelector((state) => state.chat.showDocumentPreview);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(chatActions.showDocumentPreviewUpdated(false));
  };

  return (
    <div className="modal" style={{ display: show ? 'flex' : 'none' }}>
      <div
        className="w-layout-vflex modal-container"
        style={{ minHeight: '80vh', maxWidth: '70vw' }}
      >
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{file?.content}</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleClose}
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div
          className="w-layout-vflex"
          style={{ width: '100%', height: '100%' }}
        >
          {file?.fileType?.startsWith('image') ? (
            <div>
              <img style={{ width: '100%' }} src={file?.url} alt="" />
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              <iframe
                src={`${file?.url}#zoom=100&toolbar=0&navpanes=0&scrollbar=0`}
                style={{ width: '100%', height: '80vh' }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
