import { createSlice } from '@reduxjs/toolkit';

export const navigationInitialState = {
  data: {
    menus: {
      settings: false,
      user: false,
      help: false,
    },
    modals: {
      'llm-modal': false,
      'featured-mentors-modals': false,
      'mentor-list-modal': false,
    },
    sidebar: false,
  },
  instructorMode: true,
  darkMode: false,
  iframeCloseButton: false,
  tenantCustomization: {
    enabled: false,
    metadata: null,
  },
  chatHistoryExportData: null,
};

export const navigationSlice = createSlice({
  name: 'header',
  initialState: navigationInitialState,
  reducers: {
    reset: () => navigationInitialState,
    navigationUpdated: (state, action) => {
      state.data = action.payload;
    },
    instructorModeUpdated: (state, action) => {
      state.instructorMode = action.payload;
    },
    darkModeUpdated: (state, action) => {
      state.darkMode = action.payload;
    },
    iframeCloseButtonEnabled: (state, action) => {
      state.iframeCloseButton = action.payload;
    },
    tenantCustomizationUpdated: (state, action) => {
      state.tenantCustomization = action.payload;
    },
    chatHistoryExportDataUpdated: (state, action) => {
      state.chatHistoryExportData = action.payload;
    },
  },
});

export const navigationActions = navigationSlice.actions;
export default navigationSlice.reducer;
