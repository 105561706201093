import Userpromptbox from '../../../components/Userpromptbox/Userpromptbox';
import React from 'react';
import { getFileExtension } from '../../../utils/shared';
import { useDispatch } from 'react-redux';
import { chatActions } from '../../../lib/redux/chat/slice';

const UploadedDocument = ({ message }) => {
  const dispatch = useDispatch();

  const handleOpenFilePreview = () => {
    dispatch(chatActions.documentMessageUpdated(message));
    dispatch(chatActions.showDocumentPreviewUpdated(true));
  };

  return (
    <div className="user-file-message">
      {message?.fileType?.startsWith('image/') ? (
        <div
          title={message?.content}
          className="user-file-message-content"
          onClick={handleOpenFilePreview}
        >
          <img src={message.url} alt="" />
          <div className="user-file-message-title text-inline">
            {message?.content}
          </div>
        </div>
      ) : (
        <div
          title={message?.content}
          onClick={handleOpenFilePreview}
          className="user-file-message-content"
        >
          <iframe
            src={`${message.url}#zoom=fit&toolbar=0&navpanes=0&scrollbar=0`}
            scrolling="no"
          ></iframe>
          <div className="user-file-message-title text-inline">
            {message?.content}
          </div>
        </div>
      )}
    </div>
  );
};

const DocumentUploaded = ({ content }) => (
  <div className="file-prompt-template">
    <div className="user-prompt-text-container user-file-prompt-container">
      <div className="user-prompt-text-block file-block">
        <div className="w-layout-hflex file-prompt-block">
          <div className="w-layout-hflex file-prompt-icon-block">
            <img
              src="/images/file-text-1.svg"
              loading="lazy"
              alt=""
              className="file-prompt-icon"
            />
          </div>
          <div className="w-layout-vflex file-prompt-uploaded-info-block">
            <h6 className="file-upload-name-block uploaded">{content}</h6>
            <div className="file-upload-extension-block uploaded">
              {getFileExtension(content)}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default function UserQuery({ message }) {
  return (
    <div className="user-prompt-template">
      {message.contentType === 'file' ? (
        <UploadedDocument message={message} />
      ) : (
        <Userpromptbox message={message} />
      )}
    </div>
  );
}
