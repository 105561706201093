import { useSelector } from 'react-redux';
import { useTriggers } from '../../../hooks/navigation';
import { toast } from 'react-hot-toast';
import { BASE_API_URL } from '../../../utils/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function MentorEmbedWidget({ mentor, redirectToken }) {
  const auth = useSelector((state) => state.auth.data);
  const [embedCode, setEmbedCode] = useState('');
  const url = new URL(window.location.href);
  const urlOrigin = url.origin;
  const { handleModalCloseClick } = useTriggers();
  const { t } = useTranslation();

  const getUrl = (mode = '', extraBodyClasses = '') => {
    let url = `${urlOrigin}/platform/${auth?.tenant?.key}/${mentor?.slug}?embed=true`;
    if (mode === 'anonymous') {
      url += '&mode=anonymous';
    }
    if (extraBodyClasses) {
      url += `&extra-body-classes=${extraBodyClasses}`;
    }
    if (mentor.mode === 'advanced') {
      url += `&chat=advanced`;
    }
    return url;
  };

  const getBubbleImage = async () => {
    let bubbleImg = `${urlOrigin}/images/ibl-logo-animated.gif`;

    const tenant = auth?.tenant?.key;
    const url = `${BASE_API_URL}/api/core/orgs/${tenant}/thumbnail/`;
    try {
      const response = await fetch(url);
      if (response.ok) {
        bubbleImg = url;
      } else {
        if (tenant !== 'main') {
          const url2 = `${BASE_API_URL}/api/core/orgs/main/thumbnail/`;
          const response2 = await fetch(url2);
          if (response2.ok) {
            bubbleImg = url2;
          }
        }
      }
    } catch (e) {}

    return bubbleImg;
  };

  const getEmbedCode = async (mode) => {
    const bubbleImgUrl = await getBubbleImage();
    return `
            <script>
            
            window.onload = function() {
              let isEmbeddedMentorReady = false;
              let embedTenant = "${auth.tenant?.key}";
              const iblData = new URL(window.location.href).searchParams.get("ibl-data");
              if (iblData) {
                const url = new URL(window.location);
                url.searchParams.delete("ibl-data");
                window.history.replaceState({}, document.title, url);
                const userData = JSON.parse(iblData).userData;
                document.cookie = \`userData=\${userData}; domain=\${document.domain}; path=/;\`;
              }
              
                ${
                  mentor.is_context_aware
                    ? `function getCleanBodyContent() {
                        const bodyClone = document.body.cloneNode(true);
                        const selectorsToRemove = [
                            'script', 
                            'style', 
                            'nav', 
                            'footer', 
                            '.ads', 
                            '.sidebar', 
                            '.popup', 
                            '.cookie-banner', 
                            '#ibl-chat-widget-container', 
                            '.ibl-chat-bubble'
                        ];
                        selectorsToRemove.forEach(selector => {
                            const elements = bodyClone.querySelectorAll(selector);
                            elements.forEach(element => element.remove());
                        });
                        const removeComments = (node) => {
                            for (let i = 0; i < node.childNodes.length; i++) {
                                const child = node.childNodes[i];
                                if (child.nodeType === 8) { // Node.COMMENT_NODE
                                    node.removeChild(child);
                                    i--; // Adjust index to account for removed node
                                } else if (child.nodeType === 1) { // Node.ELEMENT_NODE
                                    removeComments(child);
                                }
                            }
                        };
                        removeComments(bodyClone);
                        return bodyClone.innerHTML;
                      }

                function sendHostInfoToIframe() {
                  const iframe = document.querySelector('#ibl-chat-widget-container iframe');
                  if (iframe && iframe.contentWindow) {
                    const bodyContent = getCleanBodyContent();
                    const payload = {
                      reason: 'CONTEXT',
                      hostInfo: {
                        title: document.title,
                        href: window.location.href
                      },
                      pageContent: bodyContent
                    }
                    iframe.contentWindow.postMessage(payload, '*');
                  }
                }`
                    : ''
                };

                function sendAuthDataToIframe(iblData) {
                    const iframe = document.querySelector('#ibl-chat-widget-container iframe');
                    if (iframe && iframe.contentWindow) {
                        iframe.contentWindow.postMessage(iblData, '*');
                    }
                }

                function isTokenExpired(token_expires) {
                  const expirationDate = new Date(token_expires);
                  const now = new Date();
                  return now >= expirationDate;
                }

                function redirectToAuthSPA(forceLogout) {
                    const redirectPath = window.location.pathname + window.location.search;
                    window.location.href = \`${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-path=\${redirectPath}${!mentor.allow_anonymous ? '&redirect-token=' + redirectToken : ''}&tenant=${auth?.tenant.key}${mentor.auth_backend ? '&custom_sso_backend_path=' + mentor.auth_backend : ''}\${forceLogout ? '&logout=true' : ''}\`;
                }

                function toggleWidget() {
                  const widget = document.getElementById('ibl-chat-widget-container');
                  if (widget.style.display === 'none') {
                      widget.style.display = '';
                  } else {
                      widget.style.display = 'none';
                  }
                }

                function initChatWidget() {
                    //Creating Appropriate Meta Tag for optimized mobile display 
                    const metaViewportTagEl = document.createElement('meta')
                    metaViewportTagEl.name="viewport"
                    metaViewportTagEl.content="width=device-width, initial-scale=1"
                    document.head.appendChild(metaViewportTagEl)
                    
                    //Optimizing mobile display
                    const additionStyleEl = document.createElement('style')
                    additionStyleEl.innerHTML = "@media screen and (max-width:768px){#ibl-chat-widget-container{width:90%!important;max-width:400px!important;right:20px!important}img.ibl-chat-bubble{right:20px!important}}"
                    document.head.appendChild(additionStyleEl)
                    
                    //
                    const iframeContainer = document.createElement('div');
                    iframeContainer.id = 'ibl-chat-widget-container';
                    ${
                      mentor.mode === 'default'
                        ? `iframeContainer.style = "${mentor.auto_open ? '' : 'display:none; '}position: fixed; border: 1px solid #dfdfdf; bottom: 96px; right: 60px; z-index: 2147483647; width: 400px; height: 82%;";`
                        : `iframeContainer.style = "${mentor.auto_open ? '' : 'display:none; '}position: fixed; border: 1px solid #dfdfdf; bottom: 96px; right: 60px; z-index: 2147483647; width: 400px; height: 96%; top: 15px; right: 15px;";`
                    }
                    const bubble = document.createElement('img');
                    bubble.src = '${bubbleImgUrl}';
                    bubble.classList.add('ibl-chat-bubble');
                    ${
                      mentor.mode === 'default'
                        ? 'bubble.style = "position: fixed; right: 60px; bottom: 20px; height: 50px; cursor:pointer;";'
                        : 'bubble.style = "position: fixed; right: 15px; top: 15px; height: 50px; cursor:pointer;";'
                    } 
                    bubble.addEventListener('click', toggleWidget);
                    
                    const iframe = document.createElement('iframe');
                    iframe.src = '${getUrl(mode, 'iframed-externally')}';
                    ${
                      mentor.mode === 'default'
                        ? 'iframe.style = "border: 0px white; height:100%;width:100%;border-radius: 13px;";'
                        : 'iframe.style = "border: 0px white; height:100%;width:100%;border-radius: 0;";'
                    }
                    iframe.allow = "clipboard-read; clipboard-write";

                    iframeContainer.appendChild(iframe);
                    document.body.appendChild(iframeContainer);
                    document.body.appendChild(bubble);
                }

                ${
                  mentor.is_context_aware
                    ? `
                let lastUrl = window.location.href;
                setInterval(() => {
                    const currentUrl = window.location.href;
                    if (currentUrl !== lastUrl) {
                        lastUrl = currentUrl;
                        isEmbeddedMentorReady && sendHostInfoToIframe();
                    }
                }, 1000);
                `
                    : ''
                }
                initChatWidget();
                window.addEventListener('message', function(event) {
                    let message = event.data;
                    if (typeof message === 'string') {
                        try {
                            message = JSON.parse(message);
                        } catch (error) {
                            return;
                        }
                    }
                    ${
                      !mentor.allow_anonymous
                        ? `
                        if (message?.loaded && message?.auth?.axd_token){
                          const _userData = document.cookie.includes('userData=') ? document.cookie.split('userData=')[1].split(';')[0] : null;
                          !_userData && redirectToAuthSPA(true);
                        }
                        
                        if (
                          message?.loaded &&
                          (!message.auth.axd_token ||
                            !message.auth.dm_token ||
                            message.auth.tenant !== embedTenant ||
                            isTokenExpired(message.auth.dm_token_expires) ||
                            isTokenExpired(message.auth.axd_token_expires))
                        ) {
                          !iblData && redirectToAuthSPA();
                        } 
                        
                        if (message?.loaded && message.auth.userData ) {
                        const userData = document.cookie.includes('userData=') ? document.cookie.split('userData=')[1].split(';')[0] : null;
                        if (userData ) {
                            try {
                                const parsedUserData = JSON.parse(userData);
                                if (parsedUserData.user_id !== JSON.parse(message.auth.userData).user_id) {
                                  if (iblData) {
                                    sendAuthDataToIframe(iblData);
                                  } else {
                                    ${!mentor.allow_anonymous ? 'redirectToAuthSPA();' : ''}
                                  }
                                }
                            } catch (error) {
                                console.error('Error parsing userData cookie:', error);
                            }
                        }
                        }`
                        : ''
                    }
                    
                    if (message?.authExpired) {
                      ${!mentor.allow_anonymous ? 'redirectToAuthSPA(true);' : ''}
                    } else if (message?.ready) {
                      isEmbeddedMentorReady = true
                      if(iblData){
                        sendAuthDataToIframe(iblData);
                      }else{
                        ${!mentor.allow_anonymous ? 'redirectToAuthSPA();' : ''}
                      }
                     
                    }
                    if(message?.loaded){
                      ${mentor.is_context_aware ? 'sendHostInfoToIframe();' : ''}
                    }
                    if (message?.closeEmbed) {
                      toggleWidget();
                    }
                });
            };
            </script>
        `;
  };

  const prepareEmbedCode = async () => {
    const script = await getEmbedCode('anonymous');
    setEmbedCode(script);
  };

  useEffect(() => {
    prepareEmbedCode();
  }, [mentor]);

  const handleCopyScript = async (mode) => {
    const script = await getEmbedCode(mode);
    await navigator.clipboard.writeText(script);
    toast.success('Copied to clipboard');
  };

  return (
    <div className="modal embed-mentor-modal">
      <div className="w-layout-vflex modal-container embed-mentor-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Embed Mentor</h3>
          <div onClick={handleModalCloseClick} className="modal-close-wrapper">
            <img src="/images/close_1close.png" loading="lazy" alt="" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <>
            <hr />
            <div className="w-layout-vflex share-script-container">
              <div className="share-desc-label">
                {t(
                  'To add a mentor to your website and allow users to chat add this script tag to your HTML.'
                )}
                <br />
              </div>
              {!mentor.allow_anonymous && (
                <div>
                  <strong>{t('NOTE')}: </strong>
                  <span>{t('Websites in iframe are not supported.')}</span>
                  <br />
                </div>
              )}
              <pre
                style={{ whiteSpace: 'nowrap' }}
                className="code-container lighter-grey-bg-dark-mode"
              >
                <code className="code-block">{embedCode}</code>
              </pre>
              <div
                data-w-id="b0617eed-e4bb-eb50-9b62-14152da483e2"
                onClick={() => handleCopyScript('anonymous')}
                className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode"
              >
                <div className="copy-label">Copy Script</div>
                <img
                  src="/images/copy.svg"
                  loading="lazy"
                  alt=""
                  className="copy-icon"
                />
                <img
                  src="/images/check.svg"
                  loading="lazy"
                  alt=""
                  className="copy-icon copied-icon"
                />
              </div>
            </div>
          </>

          <div
            className="w-layout-vflex share-script-container"
            style={{ display: 'none' }}
          >
            <div className="share-desc-label">
              To add the mentor any where on your website, add this iframe to
              your html code
              <br />
            </div>
            <div className="code-container lighter-grey-bg-dark-mode">
              <p className="code-block">
                /
                <br />
              </p>
            </div>
            <div className="w-layout-hflex flex-block-73">
              <div
                data-w-id="9e30f220-f64f-eff8-10c4-2f53ce9b13b4"
                className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode"
              >
                <div className="copy-label">Copy</div>
                <img
                  src="/images/copy.svg"
                  loading="lazy"
                  alt=""
                  className="copy-icon"
                />
                <img
                  src="/images/check.svg"
                  loading="lazy"
                  alt=""
                  className="copy-icon copied-icon"
                />
              </div>
              <a
                href="https://ibl.ai/"
                target="_blank"
                className="visit-link-block w-inline-block"
              >
                <div className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode">
                  <div className="copy-label">Visit</div>
                  <img
                    src="/images/external-link-1.svg"
                    loading="lazy"
                    alt=""
                    className="copy-icon"
                  />
                  <img
                    src="/images/check.svg"
                    loading="lazy"
                    alt=""
                    className="copy-icon copied-icon"
                  />
                </div>
              </a>
            </div>
          </div>
          <div
            className="w-layout-vflex share-script-container"
            style={{ display: 'none' }}
          >
            <div className="share-desc-label">
              {t(
                'To add the mentor Iframe any where on your website, add this iframe to your html code'
              )}
              <br />
            </div>

            <div className="code-container lighter-grey-bg-dark-mode">
              <p className="code-block">
                &lt;iframe
                <br />
                src=&quot;/&quot;
                <br />
                width=&quot;100%&quot;
                <br />
                style=&quot;height: 100%; min-height: 700px;
                max-width:380px&quot;
                <br />
                frameborder=&quot;0&quot;
                <br />
                &gt;&lt;/iframe&gt;
              </p>
            </div>
            <div
              data-w-id="89385c18-7511-f41a-5feb-44d6b0968ca5"
              className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode"
            >
              <div className="copy-label">Copy Iframe</div>
              <img
                src="/images/copy.svg"
                loading="lazy"
                alt=""
                className="copy-icon"
              />
              <img
                src="/images/check.svg"
                loading="lazy"
                alt=""
                className="copy-icon copied-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
