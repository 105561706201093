import React from 'react';
import Chat from './share-chat';
import ShareHeader from '../header';
import ShareSidebar from '../sidebar';
import { useSelector } from 'react-redux';

export default function ShareChat() {
  const embed = useSelector((state) => state.embed);
  const handleRedirectToMentor = () => {
    window.location.assign('/');
  };
  return (
    <div className="body">
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/custom-styles.css);
          @import url(/css/normalize.css);
          @import url(/css/components.css);
          @import url(/css/ibl-aimentorpro-v2.css);
          @import url(/custom-assets/custom.css);


          @supports (-webkit-overflow-scrolling: touch) {
             input:focus, textarea:focus {
               font-size: 16px;
             }
          }



          .guided-question-block-old{
          	width:calc(100% - 30px)!important;
          }
          body.dark-mode .modal .modal-container{
          	border: solid 1px #ffffff75;
          }
          .menu-container .menu-element-block.current .menu-element-bt-border{
          	border-color: #2467eb!important;
          }
          .ai-response-text-container :is(h1, h2, h3, h4, h5, h6){
          	margin-top:0px;
            margin-bottom:0px;
          }
          .ai-response-text-container pre {
              display: block;
              padding: 9.5px;
              margin: 0 0 10px;
              font-size: 13px;
              line-height: 1.42857143;
              color: #333;
              word-break: break-all;
              word-wrap: break-word;
              background-color: #f5f5f5;
              border: 1px solid #ccc;
              border-radius: 4px;
          }
          .ai-response-container-content{
            word-break: break-word;
          }
          .ai-response-text-container td, .ai-response-text-container th {
              background-color: #f9f9f9;
              padding: 8px;
              line-height: 1.42857143;
              vertical-align: top;
              border-top: 1px solid #ddd;
          }
          .ai-response-text-container th{
          	  vertical-align: bottom;
              border-bottom: 2px solid #ddd;
          }
          .ai-response-text-container img {
              max-width: 35%;
          }
          .audio-chat-mentor-image.is-speaking {
            animation: zoomInOut 2s linear infinite;
          }
          @keyframes zoomInOut {
            0% {
              transform: scale3d(1, 1, 1);
            }
            50% {
              transform: scale3d(0.93, 0.93, 0.93);
            }
            100% {
              transform: scale3d(1, 1, 1);
            }
          }
          
          @media screen and (max-width: 479px) {
    .upper-area {
        padding-top: unset!important;
    }
    .body > .site-container .site-right-content-block .main-content {
        height: calc(100% - 80px) !important;
        left: 24px !important;
        width: calc(100% - 23px) !important;
    }
    .site-right-content-block {
        padding-right: 15px!important;
    }
    .bottom-area {
        margin-bottom: unset!important;
        padding-bottom: unset!important;
    }
    .main-content-body{
        height: calc(100% - 10px)!important;;
    }
    .body-right-side {
        height: 100%!important;
    }
    .bottom-area .input-submit-container {
        margin-bottom: 33px!important;
    }
}
@media screen and (min-width: 480px) {
    .site-right-content-block{
        padding-right: 10px!important;
    }
    .main-content .share-content-body{
        margin-left: 0px !important;
        margin-top: 0px !important;
        height: 100% !important;
    }
    .body > .site-container .site-right-content-block .main-content {
        height: calc(100% - 80px) !important;
    }
    .main-content .site-right-content-block{
        overflow: unset!important;
    }
}

        `,
        }}
      />
      <div className="w-layout-hflex site-container">
        <ShareSidebar handleRedirectToMentor={handleRedirectToMentor} />
        <div className="w-layout-hflex site-right-content-block">
          <ShareHeader handleRedirectToMentor={handleRedirectToMentor} />
          <div
            className="main-content here"
            style={
              embed.mode === 'advanced'
                ? { marginTop: '92px' }
                : { fontWeight: 'bold' }
            }
          >
            <div className="w-layout-hflex site-container">
              <Chat handleRedirectToMentor={handleRedirectToMentor} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
