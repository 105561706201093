import React from 'react';
import './Addnewpromptmodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import useCreatePrompt from '../../hooks/api/prompts/useCreatePrompt';
import { useSelector } from 'react-redux';
import usePromptCategories from '../../hooks/api/prompts/usePromptCategories';
import { useTranslation } from 'react-i18next';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';

const Addnewpromptmodalbox = (props) => {
  const prompts = useSelector((state) => state.prompts.data);
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const { handleModalCloseClick } = useTriggers();
  const { loading } = usePromptCategories();
  const { handleChange, handleSubmit, creating, prompt } = useCreatePrompt();
  const { t } = useTranslation();
  return (
    <div className="modal add-new-prompt-modal">
      <div className="w-layout-vflex modal-container integration-modal-container add-new-prompt-modal-container">
        <div className="w-layout-hflex modal-header add-prompt-modal-header">
          <div
            className="w-layout-hflex get-back-btn greyish-bg-in-darkmode"
            data-w-id="b1c89d75-a2ac-e78c-4a69-d79e04966a00"
            onClick={handleModalCloseClick}
          >
            <img
              alt=""
              className="image-41"
              loading="lazy"
              src="/images/move-left-1.svg"
            />
          </div>
          <div className="w-layout-hflex flex-block-28">
            {mentor ? (
              <h3 className="modal-header-title">
                {t('Add Suggested Prompt')}
              </h3>
            ) : (
              <div className="form-block-6 w-form">
                <form
                  data-name="Email Form 6"
                  data-wf-element-id="4b0a96c0-0ed4-0c70-1eb1-f987726d894a"
                  data-wf-page-id="650305782bc61751e5765214"
                  id="email-form-6"
                  method="get"
                  name="email-form-6"
                >
                  <input
                    className="add-new-prompt-input-title black-bg-dark-mode w-input"
                    data-name="Name 3"
                    id="name-3"
                    maxLength={256}
                    onChange={handleChange}
                    disabled={creating}
                    name="prompt"
                    placeholder={t('Type the name of your prompt here')}
                    type="text"
                  />
                </form>
              </div>
            )}
          </div>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-full p-4">
          <RichTextEditor
            value={prompt?.description}
            onChange={handleChange}
            name="description"
            readOnly={creating}
            placeholder="Type your prompt"
          />
        </div>
        <div className="w-layout-hflex p-4 flex-block-32">
          <SubmitBtn
            saving={creating}
            label={'Submit'}
            onClick={creating ? () => {} : handleSubmit}
            className="default-btn w-button"
            href="#"
          />
          {/*<a onClick={handleSubmit} className="default-btn w-button" href="#">
              {creating ? <span>Please wait...</span> : <span>Submit</span>}
            </a>*/}
        </div>
        <div
          className="w-layout-vflex modal-body integration-modal-body add-new-prompt-body"
          style={{ display: 'none' }}
        >
          <div className="w-layout-vflex add-new-prompt-content">
            <div className="w-layout-hflex prompt-section-header-block">
              <div className="w-layout-hflex flex-block-33">
                <div className="w-layout-hflex prompt-section-header-icon-container all-whiten-in-dark-mode">
                  <img
                    alt=""
                    className="image-42"
                    loading="lazy"
                    src="/images/chevron-down-3.svg"
                  />
                </div>
                <h5 className="heading-9">{t('Prompt')}</h5>
              </div>
              {!mentor && (
                <div className="form-block-5 w-form">
                  <form
                    data-name="Email Form 5"
                    data-wf-element-id="8c96728d-fd60-6b18-d108-e1c76e03af23"
                    data-wf-page-id="650305782bc61751e5765214"
                    id="email-form-5"
                    method="get"
                    name="email-form-5"
                  >
                    <select
                      className="prompt-select-category whiten-in-dark-mode w-select"
                      data-name="Field 2"
                      id="field-2"
                      onChange={handleChange}
                      disabled={creating}
                      name="category"
                    >
                      <option value="">{t('Select Category')}</option>
                      {prompts?.availableCategories?.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </form>
                </div>
              )}
            </div>
            <div className="prompt-section-content-block w-form">
              <form
                className="prompt-form"
                data-name="Email Form 4"
                data-wf-element-id="16db516f-4915-dd7e-0060-0391f587265f"
                data-wf-page-id="650305782bc61751e5765214"
                id="email-form-4"
                method="get"
                name="email-form-4"
              >
                {/*<textarea*/}
                {/*  className="prompt-textarea-input black-bg-dark-mode whiten-in-dark-mode w-input"*/}
                {/*  data-name="Field"*/}
                {/*  defaultValue={''}*/}
                {/*  id="field"*/}
                {/*  maxLength={5000}*/}
                {/*  disabled={creating}*/}
                {/*  name="description"*/}
                {/*  onChange={handleChange}*/}
                {/*  placeholder="Write a prompt and then click on Submit"*/}
                {/*></textarea>*/}
              </form>
            </div>
          </div>
          <div
            className="w-layout-vflex add-new-prompt-content"
            style={{ display: 'none' }}
          >
            <div className="w-layout-hflex prompt-section-header-block">
              <div className="w-layout-hflex flex-block-33">
                <div className="w-layout-hflex prompt-section-header-icon-container all-whiten-in-dark-mode">
                  <img
                    alt=""
                    className="image-42"
                    loading="lazy"
                    src="/images/chevron-down-3.svg"
                  />
                </div>
                <h5 className="heading-9">{t('Response')}</h5>
              </div>
              <div className="w-layout-hflex switcher-container add-new-prompt-switcher-container">
                <div className="switch-html-embed w-embed">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round">
                      <img
                        alt=""
                        className="left-img"
                        src="/custom-assets/img/minus.svg"
                      />
                      <img
                        alt=""
                        className="right-img"
                        src="/custom-assets/img/check.svg"
                      />
                    </span>
                  </label>
                </div>
                <h5 className="switch-label-txt whiten-in-dark-mode prompt-markdown-label">
                  {t('Markdown')}
                </h5>
              </div>
            </div>
            <div className="w-layout-hflex add-new-prompt-result-container light-grey-bg-dark-mode">
              <div className="text-block-36">
                {t(
                  'This model will generate a response after you click Submit'
                )}
              </div>
            </div>
          </div>
          <div className="w-layout-hflex flex-block-32">
            <SubmitBtn
              saving={creating}
              label={'Submit'}
              onClick={creating ? () => {} : handleSubmit}
              className="default-btn w-button"
              href="#"
            />
            {/*<a onClick={handleSubmit} className="default-btn w-button" href="#">
              {creating ? <span>Please wait...</span> : <span>Submit</span>}
            </a>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addnewpromptmodalbox;
