import { createSlice } from '@reduxjs/toolkit';

export const chatInitialState = {
  data: {
    prompt: '',
  },
  sequence: 1,
  pinMessages: null,
  sessionId: null,
  recentMessages: null,
  moreRecentMessages: [],
  guidedPrompts: null,
  generating: false,
  streaming: false,
  messages: null,
  pageContent: null,
  recording: false,
  processingRecording: false,
  showDisclaimer: true,
  tabbedMessages: null,
  tabbedSessions: null,
  showDocumentPreview: false,
  documentMessage: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: chatInitialState,
  reducers: {
    reset: () => chatInitialState,
    chatUpdated: (state, action) => {
      state.data = action.payload;
    },
    pinMessagesUpdated: (state, action) => {
      state.pinMessages = action.payload;
    },
    recentMessagesUpdated: (state, action) => {
      state.recentMessages = action.payload;
    },
    moreRecentMessagesUpdated: (state, action) => {
      state.moreRecentMessages = action.payload;
    },
    guidedPromptsUpdated: (state, action) => {
      state.guidedPrompts = action.payload;
    },
    generatingUpdated: (state, action) => {
      state.generating = action.payload;
    },
    streamingUpdated: (state, action) => {
      state.streaming = action.payload;
    },
    messagesUpdated: (state, action) => {
      state.messages = action.payload;
    },
    tabbedMessagesUpdated: (state, action) => {
      state.tabbedMessages = action.payload;
    },
    tabbedSessionsUpdated: (state, action) => {
      state.tabbedSessions = action.payload;
    },
    sessionUpdated: (state, action) => {
      state.sessionId = action.payload;
    },
    pageContentUpdate: (state, action) => {
      state.pageContent = action.payload;
    },
    recordingUpdated: (state, action) => {
      state.recording = action.payload;
    },
    processingRecordingUpdated: (state, action) => {
      state.processingRecording = action.payload;
    },
    chatSequenceUpdated: (state, action) => {
      state.sequence = action.payload;
    },
    showDisclaimerUpdated: (state, action) => {
      state.showDisclaimer = action.payload;
    },
    showDocumentPreviewUpdated: (state, action) => {
      state.showDocumentPreview = action.payload;
    },
    documentMessageUpdated: (state, action) => {
      state.documentMessage = action.payload;
    },
  },
});

export const chatActions = chatSlice.actions;
export default chatSlice.reducer;
