import { useDispatch, useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { useEffect, useRef, useState } from 'react';
import { exportTableToExcel, userIsOnTrial } from '../../../utils/shared';
import { toast } from 'react-hot-toast';
import { Error } from '../../../modules/alerts';
import { useTriggers } from '../../navigation';
import { navigationActions } from '../../../lib/redux/navigation/slice';

export default function useMentorHistory() {
  const auth = useSelector((state) => state.auth.data);
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTopicsMenu, setShowTopicsMenu] = useState(false);
  const [showSentimentScoreMenu, setShowSentimentScoreMenu] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [daterange, setDateRange] = useState({ startDate: '', endDate: '' });
  const [sentimentScore, setSentimentScore] = useState('');
  const [offset, setOffset] = useState(null);
  const [page, setPage] = useState(null);
  const api = useApi();
  const topicsRef = useRef();
  const sentimentScoreRef = useRef();
  const [username, setUsername] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const { handleOpenFreeTrialModal } = useTriggers();
  const dispatch = useDispatch();
  const [chatExportLoading, setChatExportLoading] = useState(false);

  const loadMentorHistoryFilters = async () => {
    if (userIsOnTrial()) {
      return;
    }

    const url = `/api/ai-analytics/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/chat-history-filter/`;
    const response = await api.get(url);
    if (response?.data) {
      setFilters(response.data);
    }
  };

  const loadTenantUsers = async (search) => {
    if (userIsOnTrial()) {
      handleOpenFreeTrialModal();
      return;
    }

    const url = `/api/core/platform/users`;
    const params = {
      platform_key: auth?.tenant.key,
      query: search ?? '',
    };
    setLoadingUsers(true);
    const response = await api.get(
      url,
      params,
      localStorage.getItem('dm_token')
    );
    if (response?.data) {
      const data = response?.data?.results?.map((item) => {
        return { value: item.username, label: item.name || item.username };
      });
      return data;
    }
    setLoadingUsers(false);
    return [];
  };

  const createExportTable = () => {
    let table = document.createElement('table');
    table.id = 'history-export-message-table';
    table.style.display = 'none';

    let content = `
          <thead>
              <tr>
                  <th>Session ID</th>
                  <th>Human</th>
                  <th>AI</th>
                  <th>Timestamp</th>
                  <th>User</th>
                  <th>Sentiment</th>
              </tr>
          </thead>
          <body>
      `;

    data?.results?.forEach((item) => {
      item?.messages?.forEach((message) => {
        content += `<tr>
                    <td>${item?.id}</td>
                    <td>${message.human}</td>
                    <td>${message?.ai}</td>
                    <td>${item.inserted_at}</td>
                    <td>${item?.student ?? 'Anonymous'}</td>
                    <td>${item?.sentiment}</td>
                </tr>`;
      });
    });

    content += `</body>`;
    table.innerHTML = content;

    document.body.appendChild(table);
  };

  const handleExportMessages = async () => {
    if (userIsOnTrial()) {
      handleOpenFreeTrialModal();
      return;
    }
    setChatExportLoading(true);
    const url = `/api/reports/orgs/${auth?.tenant?.key}/new`;
    const response = await api.post(url, {
      report_name: 'ai-mentor-chat-history',
      ...getFilterFormParams(),
    });
    if (response?.data) {
      dispatch(
        navigationActions.chatHistoryExportDataUpdated(response?.data?.data)
      );
    } else {
      toast.error(
        'An error occurred while trying to export chat history. Try again later.',
        {
          autoClose: 3000,
        }
      );
    }
    setChatExportLoading(false);

    //console.log(' handleExportMessages ', response);

    /* createExportTable();
    exportTableToExcel('history-export-message-table');
    toast.success(
      'Messages exported successfully! Download should start shortly.'
    );*/
  };

  const getFilterFormParams = () => {
    return {
      topics: encodeURIComponent(selectedTopics.join(',')),
      start_date: daterange.startDate,
      end_date: daterange.endDate,
      sentiment: sentimentScore,
      mentor: editMentor.unique_id,
    };
  };

  const loadMentorHistory = async () => {
    if (userIsOnTrial()) {
      handleOpenFreeTrialModal();
      return;
    }

    const url = `/api/ai-analytics/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/chat-history/`;
    const params = getFilterFormParams();
    if (page) {
      params.page = page;
    }
    if (username) {
      params.user_id = username;
    }
    setLoading(true);
    const response = await api.get(url, params);
    if (response?.data) {
      setData(response.data);
      if (response?.data?.results?.length > 0) {
        setSelected(response.data.results[0]);
      }
    } else {
      toast.custom(Error('Could not load data.'));
    }
    setLoading(false);
  };

  const handleTopicChange = (event) => {
    const { name, checked } = event.target;
    let topics = [];
    if (checked) {
      topics = [...selectedTopics, name];
    } else {
      topics = selectedTopics.filter((item) => item !== name);
    }
    setSelectedTopics(topics);
  };

  useEffect(() => {
    loadMentorHistory();
  }, [selectedTopics, daterange, sentimentScore, page, username]);

  const handleClickOutsideMenus = (e) => {
    if (
      topicsRef.current &&
      !topicsRef.current.contains(e.target) &&
      showTopicsMenu
    ) {
      setShowTopicsMenu(false);
    }
    if (
      sentimentScoreRef.current &&
      !sentimentScoreRef.current.contains(e.target) &&
      showSentimentScoreMenu
    ) {
      setShowSentimentScoreMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenus);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenus);
    };
  });

  useEffect(() => {
    if (!filters) {
      loadMentorHistoryFilters();
    }
  }, []);

  return {
    data,
    filters,
    selectedTopics,
    loading,
    selected,
    setSelected,
    showSentimentScoreMenu,
    showTopicsMenu,
    topicsRef,
    sentimentScoreRef,
    setShowTopicsMenu,
    setShowSentimentScoreMenu,
    handleTopicChange,
    setDateRange,
    setSentimentScore,
    sentimentScore,
    handleExportMessages,
    setOffset,
    setPage,
    setUsername,
    loadingUsers,
    loadTenantUsers,
    chatExportLoading,
  };
}
