import useShareChat from '../../../hooks/share/useShareChat';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';
import Loader from '../../loader';
import AIResponse from '../../chat-room/upper-area/ai-response';
import UserQuery from '../../chat-room/upper-area/user-query';
import React from 'react';
import ChatForm from './chat-form';

const proactive_prompt_ending =
  'YOU MUST GREET THE USER WITH THEIR FIRST NAME IF THEIR NAME EXISTS IN THE SYSTEM PROMPT';

export default function Chat({ handleRedirectToMentor }) {
  const params = useParams();

  const { loading, data, error } = useShareChat(params?.sessionId);

  let msgToDisplay = null;
  if (data?.results?.length > 0) {
    let messages = data?.results;
    messages = [...messages].reverse();

    msgToDisplay = messages?.map((msg) => {
      return {
        type: msg?.type,
        content: msg?.content ? marked.parse(msg?.content) : '',
        rawContent: msg?.content,
        share: true,
      };
    });
  }

  return (
    <div className="w-layout-hflex site-right-content-block">
      <section
        className="main-content-body share-content-body"
        style={{ height: 'calc(100% - 30px)' }}
      >
        <div className="body-right-side light-grey-bg-dark-mode">
          <div className="upper-area">
            {loading && <Loader />}

            {msgToDisplay?.map((item, i) => {
              if (
                item.rawContent === data.proactive_prompt ||
                item?.rawContent?.includes(proactive_prompt_ending)
              ) {
                return '';
              }
              if (item?.type === 'ai') {
                return <AIResponse key={i} message={item} />;
              } else {
                return <UserQuery message={item} key={i} />;
              }
            })}
          </div>
          <ChatForm handleRedirectToMentor={handleRedirectToMentor} />
        </div>
      </section>
    </div>
  );
}
