export const transformResponse = {
  getConversationVolume: (response) =>
    response.map((item) => ({
      period: new Date(item.date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      conversationCount: item.conversation_count,
    })),

  getUserRatings: (response) =>
    response.map((item) => ({
      period: new Date(item.week).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      feedbackCount: item.feedback_count,
    })),

  getTopicsAnalytics: (response) =>
    response.map((item) => ({
      topicName: item.topic_name,
      conversationCount: item.conversation_count,
    })),

  getAverageMessagesPerSession: (response) =>
    response.map((item) => ({
      period: new Date(item.time).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      averageMessages: item.avg_messages,
      totalSessions: item.total_sessions,
    })),

  getUserSentiment: (response) => [
    {
      name: 'Positive',
      value: response.sentiment_distribution.positive,
      color: '#E3F2FD',
    },
    {
      name: 'Neutral',
      value: response.sentiment_distribution.neutral,
      color: '#90CAF9',
    },
    {
      name: 'Negative',
      value: response.sentiment_distribution.negative,
      color: '#42A5F5',
    },
  ],

  getUserCohortsOverTime: (response) => {
    return response.periods.map((date, index) => ({
      day: new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      new: response.new_users[index],
      veteran: response.veteran_users[index],
    }));
  },

  getUserMetricsPie: (response) => [
    {
      name: 'New Users',
      value: response.new_users.count,
      color: '#E3F2FD',
    },
    {
      name: 'Veteran Users',
      value: response.veteran_users.count,
      color: '#90CAF9',
    },
  ],

  getUniqueUsers: (response) => {
    return response.users_by_period.map((item) => ({
      period: new Date(item.period).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      userCount: item.user_count,
    }));
  },

  getMostEngagedUsers: (response) => {
    return response.map((item) => ({
      username: item.username,
      chatMessageCount: item.chat_message_count,
    }));
  },

  getTopTopics: (response) => {
    return response.map((item) => ({
      name: item.name,
      conversationCount: item.conversation_count,
    }));
  },
};
