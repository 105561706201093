import { useEffect, useState } from 'react';
import { isValidHttpUrl, userIsOnTrial } from '../../../utils/shared';
import { useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { BASE_API_URL } from '../../../utils/api';
import { usePageNumberPagination } from './usePageNumberPagination';
import useChatHistoryPagination from './useChatHistoryPagination';
import { useTriggers } from '../../navigation';

export default function useMentorDataset() {
  const [datasets, setDatasets] = useState(null);
  const [search, setSearch] = useState('');
  const [uri, setUri] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [offset, setOffset] = useState(null);
  const { numPages, page, getOffsetForPage } =
    useChatHistoryPagination(datasets);

  const api = useApi();

  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const nav = useSelector((state) => state.navigation.data);

  const getDatasets = async (hideLoader = false) => {
    if (userIsOnTrial()) {
      setHasLoadedOnce(true);
      setDatasets({ results: [] });
      return;
    }
    const url =
      uri ??
      `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/documents/pathways/${mentors?.edit?.mentor?.name}/`;
    if (!hideLoader) {
      setLoading(true);
    }
    const params = {
      offset: offset,
    };
    const response = await api.get(url, params);
    if (response?.data) {
      setDatasets(response?.data);
    }
    setHasLoadedOnce(true);
    setLoading(false);
  };

  const handlePageChange = (newPage) => {
    const nextOffset = getOffsetForPage(newPage);
    setOffset(nextOffset);
  };

  const getDataSetType = (item) => {
    return isValidHttpUrl(item.url) ? 'URL' : 'TEXT';
  };
  let datasetsToRender = [];

  if (datasets?.results?.length) {
    datasetsToRender = datasets?.results.map((item) => {
      const fileType = item.document_type ?? getDataSetType(item);
      return {
        ...item,
        document_type: fileType,
      };
    });
  }

  if (search && datasetsToRender?.length) {
    datasetsToRender = datasetsToRender.filter((item) =>
      item?.document_name?.toLowerCase()?.includes(search?.toLowerCase())
    );
  }

  useEffect(() => {
    getDatasets();
  }, [
    mentors?.edit?.mentor,
    nav?.modals?.['dataset-url-resource-modal'],
    uri,
    offset,
  ]);

  return {
    loading,
    datasetsToRender,
    search,
    setSearch,
    getDatasets,
    hasLoadedOnce,
    datasets,
    page,
    numPages,
    handlePageChange,
  };
}
