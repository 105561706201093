import { useCallback, useEffect, useState, useRef } from 'react';
import loadScript from 'load-script';
import useGetCredentials from '../api/mentors/useGetCredentials';

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const SCRIPT_ID = 'dropboxjs';

let scriptLoadingStarted = false;

const useDropboxPicker = ({
  cancel = () => {},
  linkType = 'preview',
  multiselect = true,
  extensions,
  disabled = false,
  autoShow = false,
}) => {
  const [dropboxReady, setDropboxReady] = useState(false);
  const [openChooser, setOpenChooser] = useState(false);
  const [appKey, setAppKey] = useState(null);
  const { handleFetchCredentials } = useGetCredentials();
  const hasFetchedCredentials = useRef(false); // Add a ref to track if credentials have been fetched
  useEffect(() => {
    const handleFetchAppKey = async () => {
      if (!hasFetchedCredentials.current) {
        // Check if credentials have been fetched
        hasFetchedCredentials.current = true;
        try {
          const data = await handleFetchCredentials('dropbox', false);
          if (data?.length > 0) {
            setAppKey(data[0].value.appKey);
          }
        } catch (e) {}
      }
    };

    handleFetchAppKey();
  }, [handleFetchCredentials]);

  const isDropboxReady = useCallback(() => !!window.Dropbox, []);

  const onChoose = useCallback(
    (success) => {
      if (!isDropboxReady() || disabled) {
        return null;
      }

      window.Dropbox.choose({
        success: success,
        cancel: cancel,
        linkType: linkType,
        multiselect: multiselect,
        extensions: extensions,
      });
    },
    [isDropboxReady, cancel, linkType, multiselect, extensions, disabled]
  );

  useEffect(() => {
    if (!isDropboxReady() && !scriptLoadingStarted && appKey) {
      scriptLoadingStarted = true;
      loadScript(
        DROPBOX_SDK_URL,
        {
          attrs: {
            id: SCRIPT_ID,
            'data-app-key': appKey,
          },
        },
        () => {
          setDropboxReady(true);
        }
      );
    } else if (isDropboxReady()) {
      setDropboxReady(true);
    }
  }, [appKey, isDropboxReady]);

  useEffect(() => {
    if (autoShow && dropboxReady && !disabled) {
      setOpenChooser(true);
    }
  }, [autoShow, dropboxReady, disabled]);

  useEffect(() => {
    if (openChooser) {
      onChoose((files) => {});
      setOpenChooser(false);
    }
  }, [openChooser, onChoose]);

  return {
    openChooser: onChoose,
    dropboxReady,
    appKey,
  };
};

export default useDropboxPicker;
