export const tabs = [
  {
    display: 'Chat',
    name: 'chat',
    showHeader: false,
    prompts: [
      {
        type: 'static',
        icon: 'question_mark',
        summary: 'Ask questions to clarify material and stay on track',
        content: 'Ask questions to clarify material and stay on track',
      },
      {
        type: 'static',
        icon: 'format_list_bulleted',
        summary: 'Summarize key takeaways for better note-taking',
        content: 'Summarize key takeaways for better note-taking',
      },
      {
        type: 'static',
        icon: 'quiz',
        summary:
          'Practice for quizzes and tests to solidify knowledge and identify gaps',
        content:
          'Practice for quizzes and tests to solidify knowledge and identify gaps',
      },
      {
        type: 'static',
        icon: 'explore',
        summary: 'Explore how is learning aligned with current or future goals',
        content: 'Explore how is learning aligned with current or future goals',
      },
    ],
  },
  {
    display: 'Summarize',
    showHeader: true,
    name: 'summarize',
    prompts: [
      {
        type: 'human',
        proactive: true,
        hide: true,
        content:
          'Please summarize the key points from the content of this webpage. Focus on the main ideas, important details, and any actionable insights or conclusions. Provide the summary in clear, concise language, and structure it in bullet points or short paragraphs for readability.',
      },
    ],
  },
  {
    display: 'Translate',
    showHeader: true,
    name: 'translate',
    prompts: [
      {
        type: 'ai',
        hide: false,
        tag: 'translate',
      },
    ],
  },
  {
    display: 'Expand',
    showHeader: true,
    name: 'expand',
    prompts: [
      {
        type: 'human',
        proactive: true,
        hide: true,
        content:
          'For each key idea, please provide more detailed explanations, examples, and any additional insights to make it more comprehensive and engaging',
      },
    ],
  },

  {
    display: 'Practice',
    showHeader: true,
    name: 'practice',
    prompts: [
      {
        type: 'human',
        proactive: true,
        hide: true,
        content:
          'Please generate questions based on the content. The questions could be "Fill in the blank Spaces", "Multiple choice" etc. Ensure they are using actual html inputs, like checkboxes, radio buttons, textareas etc. where necessary',
      },
    ],
  },
];

export const translatePrompt = (language) =>
  `Please translate the summarized the content into ${language}`;

export const getTabsAndMessagesAndSessions = () => {
  const tabbedMessages = {};
  const tabbedSessions = {};
  tabs.forEach((tab) => {
    tabbedMessages[tab.name] = tab.prompts.map((prompt) => ({
      hide: prompt.hide || false,
      ...prompt,
    }));
    tabbedSessions[tab.name] = null;
  });
  return { tabbedMessages, tabbedSessions };
};

export const getTabData = (tabKey) => {
  return tabs.find((tab) => tab.name === tabKey);
};
