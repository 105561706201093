import React from 'react';
import './Datasetresourcesmodalbox.css';
import useDatasetTypes from '../../hooks/datasets/useDatasetTypes';
import { useTriggers } from '../../hooks/navigation';
import { userIsOnTrial } from '../../utils/shared';

const Datasetresourcesmodalbox = (props) => {
  const { datasetTypes, getInActiveStatus, handleSelect } = useDatasetTypes();
  const { handleModalCloseClick, handleOpenFreeTrialModal } = useTriggers();

  const handleClickDatasetType = (item) => {
    if (userIsOnTrial()) {
      handleOpenFreeTrialModal();
      return;
    }
    if (item?.active) {
      handleSelect(item.type);
    }
  };

  return (
    <div className="modal dataset-resource-modal">
      <div className="w-layout-vflex modal-container create-secret-key-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">New Resource</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body dataset-resource-modal-body">
          <div className="w-layout-vflex data-resource-upload-box-block">
            <div className="w-layout-grid data-resource-upload-grid">
              {datasetTypes?.map((item) => (
                <div
                  onClick={() => handleClickDatasetType(item)}
                  key={item.type}
                  className={`w-layout-vflex datasource-element-picker ${getInActiveStatus(item)}`}
                >
                  <img
                    src={item.img_url}
                    loading="lazy"
                    alt=""
                    className="datasource-element-picker-icon"
                  />
                  <div className="datasource-element-picker-label">
                    {item.type}
                  </div>
                  {item.tooltip && (
                    <span className="tooltiptext">{item.tooltip}</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datasetresourcesmodalbox;
