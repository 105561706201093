import { useTriggers } from '../../../hooks/navigation';
import { DOCUMENTS_EXTENSIONS, userIsOnTrial } from '../../../utils/shared';
import React from 'react';

export default function DocumentUpload({
  handlePromptFileUploadBtnClick,
  handleFileChange,
}) {
  const { handleOpenFreeTrialModal } = useTriggers();
  return (
    <div
      data-w-id="712e5e43-6827-f046-875a-8d254cd1b749"
      className="has-custom-tooltip"
      prop-events-names="onClick"
      onClick={
        userIsOnTrial()
          ? handleOpenFreeTrialModal
          : handlePromptFileUploadBtnClick
      }
      prop-events-value-onclick="handlePromptFileUploadBtnClick"
    >
      <div className="tooltip tooltip-user-file-upload">
        Add Files (5 max, 10Mb each) Accepts pdf, txt, csv, etc.
      </div>
      <div className="code-embed-4 w-embed">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#919191"
        >
          <path d="M720-330q0 104-73 177T470-80q-104 0-177-73t-73-177v-370q0-75 52.5-127.5T400-880q75 0 127.5 52.5T580-700v350q0 46-32 78t-78 32q-46 0-78-32t-32-78v-370h80v370q0 13 8.5 21.5T470-320q13 0 21.5-8.5T500-350v-350q-1-42-29.5-71T400-800q-42 0-71 29t-29 71v370q-1 71 49 120.5T470-160q70 0 119-49.5T640-330v-390h80v390Z"></path>
        </svg>
      </div>
      <form id="attachment-form" action="#" style={{ display: 'none' }}>
        <input
          name="file"
          onChange={handleFileChange}
          id="attachment-upload"
          className="attachment-upload"
          type="file"
          accept={DOCUMENTS_EXTENSIONS.join(',')}
        />
      </form>
    </div>
  );
}
