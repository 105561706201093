import { fetchBaseQuery as reduxFetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { toast } from 'react-hot-toast';
import { handleLogout } from '@iblai/ibl-web-react-common';

import { BASE_API_URL } from '../../utils/api';
import { mentorIsIframe } from '../../utils/shared';
import { notifyParentOnTokenExpiry } from '../../utils/auth';

const baseQuery = reduxFetchBaseQuery({
  baseUrl: BASE_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('axd_token');
    if (token) {
      headers.set('Authorization', `Token ${token}`);
    }
    // remove this in favour of the isForm flag in the future
    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

export const fetchBaseQuery = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);

    if (!result.meta.response.ok) {
      const error = result.error;

      const resultStatus = result.meta.response.status;
      if (resultStatus === 401 || resultStatus === 403) {
        if (api.iblutils.isTokenExpired()) {
          if (mentorIsIframe()) {
            notifyParentOnTokenExpiry();
          } else {
            localStorage.setItem(
              'redirect-path',
              `${window.location.pathname}${window.location.search}`
            );
            handleLogout();
          }
        } else {
          console.error(
            '401 Occurred, but token is not expired. Details: ',
            error
          );
          handleLogout();
        }
        return { error };
      }

      console.error(
        `REQUEST_ERROR: ${error}; URL: ${args.method || 'GET'} ${args.url}`
      );
      toast.error(error);
      return;
    }

    try {
      return { data: result.data };
    } catch {
      return { data: 'Done' };
    }
  } catch (e) {
    console.error(e);
    toast.custom(Error('Could not make request.'));
  }
};
